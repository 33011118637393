@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  @font-face {
    font-family: "Iceland";
    src: url(./fonts/Iceland-Regular.ttf) format("truetype");
  }
  @font-face {
    font-family: "Viga";
    src: url(./fonts/Viga-Regular.ttf) format("truetype");
  }
  @font-face {
    font-family: "Bruno Ace SC";
    src: url(./fonts/BrunoAceSC-Regular.ttf) format("truetype");
  }
  @font-face {
    font-family: "Righteous";
    src: url(./fonts/Righteous-Regular.ttf) format("truetype");
  }
}
@layer components {
  .bgBlur1 {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
  .bgBlur2 {
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
  .bgBlur3 {
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
  }
  main::-webkit-scrollbar,
  #Contact::-webkit-scrollbar {
    display: none;
  }
  main,
  #Contact {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  @media (min-width: 1024px) {
    .clipArt {
      clip-path: url(#clipper);
    }
  }
}
